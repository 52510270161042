export default {
  vi: {
    domain: 'eacc.fo4.garena.vn',
    home_description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.',
    accumulate_description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.',
    complete_round_reward: 'Quà hết vòng',
    randomly_reward: 'Nhận ngẫu nhiên',
    round: 'Vòng',
    login: 'Đăng nhập',
    logout: 'Đăng xuất',
    line_reward_text: 'Special line reward',
    play_time: 'Chơi [xxx] lượt',
    play_time_select: 'Chơi [xxx] lượt (Chọn 1 trong 2)',
    select_title: 'Số lần có thể chọn quà',
    claimed: 'Đã nhận',
    spin_confirm: `Sử dụng <span class="highlight">[xxx]</span> để quay [yyy] lần`,
    spin_free_confirm: `Sử dụng <span class="highlight">[xxx]</span> và 1 lượt free để quay 1 lần`,
    play: 'Mở',
    play_free: 'Mở free',
    play_all: 'Mở toàn bộ',
    play_x10: 'Mở 10 lần',
    mission: 'Nhiệm vụ',
    menu_accumulate: 'Quà tích lũy',
    menu_play: 'Vòng quay',
    menu_guide: 'Hướng dẫn',
    menu_history: 'Lịch sử',
    menu_rewards: 'Quà nhận được',
    no_special_exchange: 'Bạn không có lượt chọn quà đặc biệt này',
    choose_your_reward: 'Chọn quà của bạn',
    can_choose_1_time: 'Bạn chỉ có thể chọn 1 trong 2',
    choose: 'Chọn',
    title_congratulations: 'Chúc mừng<br>Bạn nhận được',
    share_failed: 'Bạn đã nhận lượt từ lần share trước rồi',
    additional_free: '1 lượt free',
    reach_limit: 'Đã đổi hết',
    share_success: 'Bạn đã chia sẻ thành công và nhận được 1 lượt quay!',
    please_share: 'Hãy chia sẻ để nhận lượt quay nhé',
    cumulative_play: 'Tổng lượt chơi',
    gift_list: 'Danh sách quà',
    gift_round_1_title: 'Vòng 1 đến 5',
    gift_round_2_title: 'Vòng 6 đến 9',
    gift_round_3_title: 'Vòng 10',
    accumulate_gift: 'Quà tích lũy',
    field_no: 'STT',
    field_name: 'Tên quà',
    field_source: 'Sự kiện',
    field_time: 'Thời gian',
    field_status: 'Trạng thái',
    status_1: 'Đang xử lý',
    status_2: 'Đã gửi',
    status_3: 'Thất bại',
    history: 'Lịch sử',
    no_history: 'Chưa có lịch sử',
    accumulate_source: 'Quà tích lũy',
    mission_not_done: 'Đã hoàn thành',
    mission_done: 'Hoàn thành',
    play_now: 'Chơi ngay',

    notice: 'Thông báo',
    confirm: 'Xác nhận',
    cancel: 'Huỷ',
    congrats: 'Chúc mừng!!!',
    default_error: 'Hiện không thể thực hiện, vui lòng thử lại sau',
  }
}