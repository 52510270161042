import config from 'config/app'
const convertDateForIos = (date) => {
  var arr = date.split(/[- :]/);
  date = new Date(arr[0], arr[1]-1, arr[2], arr[3], arr[4], arr[5]);
  return date;
}

import {
  CURRENT_USER_REQUEST_ERROR,
  CURRENT_USER_REQUEST_SUCCESS,
  CURRENT_USER_FETCHING,

  GET_REWARDS_ERROR,
  GET_REWARDS_SUCCESS,
  IS_GETTING_REWARDS,

  RECEIVE_MISSION_ERROR,
  RECEIVE_MISSION_SUCCESS,
  IS_RECEIVING_MISSION,

  CHECK_MISSION_ERROR,
  CHECK_MISSION_SUCCESS,
  IS_CHECKING_MISSION,

  SHARE_ERROR,
  SHARE_SUCCESS,
  IS_SHARING,

  SPIN_ERROR,
  SPIN_SUCCESS,
  IS_SPINNING,

  GET_LANG_ERROR,
  GET_LANG_SUCCESS,
  IS_GETTING_LANG,

  GET_HISTORY_ERROR,
  GET_HISTORY_SUCCESS,
  IS_GETTING_HISTORY,

  CLAIM_ERROR,
  CLAIM_SUCCESS,
  IS_CLAIMING,

  REFRESH_ERROR,
  REFRESH_SUCCESS,
  IS_REFRESHING,

} from './actions'

const initialState = {
  loading: false,
  login: false,
  userLoaded: false,
  user: {},
  user_status: {},
  user_mission_infos: {},
  exchange_infos: [],
  special_exchange_infos: [],
  claimed_special_counts: {},
  special_reward_limit_infos: {},
  current_rewards: [],
  reward_infos: [],
  userHistory: [],
  delta: 0,
  lng: {},

  isSpinning: false,
  isSharing: false,
  isClaiming: false,
  isGettingHistory: false,
  isReceivingMission: false,
  isCheckingMission: false,
  isRefreshing: false,
}

export default function currentUserReducer(state = initialState,  { type, payload } = action) {
  switch (type) {
    case CURRENT_USER_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        login: true,
        userLoaded: true,
        user: payload.user,
        user_status: payload.user_status,
        user_mission_infos: payload.user_mission_infos,
        exchange_infos: payload.exchange_infos,
        special_exchange_infos: payload.special_exchange_infos,
        claimed_special_counts: payload.claimed_special_counts,
        special_reward_limit_infos: payload.special_reward_limit_infos,
        current_rewards: payload.current_rewards,
        //delta: new Date(convertDateForIos(payload.current_datetime)).getTime() - new Date().getTime(),
        errorGlobal: ''
      }
      break;
    case CURRENT_USER_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        userLoaded: true,
        errorGlobal: 'Không có thông tin user',
      }
      break;
    case CURRENT_USER_FETCHING:
      return {
        ...state,
        loading: true
      }
      break;

    //SELECT PLAYER
    case GET_REWARDS_SUCCESS:
      return {
        ...state,
        reward_infos: payload.reward_infos,
        isSelecting: false
      }
      break;
    case GET_REWARDS_ERROR:
      return {
        ...state,
        isSelecting: false
      }
      break;
    case IS_GETTING_REWARDS:
      return {
        ...state,
        isSelecting: true
      }
      break;

    //SPIN
    case SPIN_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          fc: payload.fc,
          mc: payload.mc
        },
        user_status: payload.user_status,
        exchange_infos: payload.exchange_infos,
        special_exchange_infos: payload.special_exchange_infos,
        claimed_special_counts: payload.claimed_special_counts,
        special_reward_limit_infos: payload.special_reward_limit_infos,
        current_rewards: payload.current_rewards,
        isSpinning: false
      }
      break;
    case SPIN_ERROR:
      return {
        ...state,
        isSpinning: false
      }
      break;
    case IS_SPINNING:
      return {
        ...state,
        isSpinning: true
      }
      break;

    //RECEIVE_MISSION
    case RECEIVE_MISSION_SUCCESS:
      return {
        ...state,
        user_mission_infos: payload.user_mission_infos,
        user_status: payload.user_status,
        isReceivingMission: false
      }
      break;
    case RECEIVE_MISSION_ERROR:
      return {
        ...state,
        isReceivingMission: false
      }
      break;
    case IS_RECEIVING_MISSION:
      return {
        ...state,
        isReceivingMission: true
      }
      break;

    //SHARE
    case SHARE_SUCCESS:
      return {
        ...state,
        user_status: {
          ...state.user_status,
          ...payload.user_status
        },
        isSharing: false
      }
      break;
    case SHARE_ERROR:
      return {
        ...state,
        isSharing: false
      }
      break;
    case IS_SHARING:
      return {
        ...state,
        isSharing: true
      }
      break;

    //CHECK MISSION
    case CHECK_MISSION_SUCCESS:
      return {
        ...state,
        user_mission_infos: payload.user_mission_infos,
        user_status: payload.user_status,
        isCheckingMission: false
      }
      break;
    case CHECK_MISSION_ERROR:
      return {
        ...state,
        isCheckingMission: false
      }
      break;
    case IS_CHECKING_MISSION:
      return {
        ...state,
        isCheckingMission: true
      }
      break;

    //GET HISTORY
    case GET_HISTORY_SUCCESS:
      return {
        ...state,
        userHistory: payload.reward_infos,
        isGettingHistory: false
      }
      break;
    case GET_HISTORY_ERROR:
      return {
        ...state,
        isGettingHistory: false
      }
      break;
    case IS_GETTING_HISTORY:
      return {
        ...state,
        isGettingHistory: true
      }
      break;

    //CLAIM
    case CLAIM_SUCCESS:
      return {
        ...state,
        special_exchange_infos: payload.special_exchange_infos,
        claimed_special_counts: payload.claimed_special_counts,
        special_reward_limit_infos: payload.special_reward_limit_infos,
        isClaiming: false
      }
      break;
    case CLAIM_ERROR:
      return {
        ...state,
        isClaiming: false
      }
      break;
    case IS_CLAIMING:
      return {
        ...state,
        isClaiming: true
      }
      break;

    //LANG
    case GET_LANG_SUCCESS:
      return {
        ...state,
        lng: payload,
        isGettingLang: false
      }
      break;
    case GET_LANG_ERROR:
      return {
        ...state,
        isGettingLang: false
      }
      break;
    case IS_GETTING_LANG:
      return {
        ...state,
        isGettingLang: true
      }
      break;

    //REFRESH
    case REFRESH_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          fc: payload.fc,
          mc: payload.mc
        },
        isRefreshing: false
      }
      break;
    case REFRESH_ERROR:
      return {
        ...state,
        isRefreshing: false
      }
      break;
    case IS_REFRESHING:
      return {
        ...state,
        isRefreshing: true
      }
      break;
  
    default:
      return state
  }
}