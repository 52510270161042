import React, { useState, useEffect } from 'react'
import { NavLink, useHistory, useParams, useLocation } from 'react-router-dom'
import queryString from 'query-string'

import config from 'config/common'
import lib from 'lib/commons'

import Sidebar from './Sidebar'
import ModalHistory from 'components/ModalHistory'
import ModalPrize from 'components/ModalPrize'
import ModalRule from 'components/ModalRule'

const Header = ({
  user: {
    user: {
      nickname = '',
      id: userId = null,
      uid = null,
    } = {},
    user_status: {
      current_hurdle_no = 0,
      free_turn_no = 0,
      free_turn_spent = 0
    } = {},
    reward_infos,
    userHistory = [],
    isGettingHistory,
    lng,
    isSpinning
  } = {},

  getHistory
}) => {
  const [modalStatus, setModalStatus] = useState(false)
  const closeModal = () => setModalStatus(false)
  const [modalPrizeStatus, setModalPrizeStatus] = useState(false)
  const closeModalPrize = () => setModalPrizeStatus(false)
  const [modalRuleStatus, setModalRuleStatus] = useState(false)
  const closeModalRule = () => setModalRuleStatus(false)
  
  useEffect(() => {
    var header = document.getElementById("header");

    var sticky = header.offsetTop;
    window.onscroll = () => {
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    };
  }, [])

  return (
    <>
      <div id="header" className={`${isSpinning ? ' is-spinning' : ''}`}>
        <div className="main-container">
          <div className="menu-list text-right">
            <NavLink to="/" className='menu-link menu-link--logo'>
              <img src="/images/logo.png" alt="" />
            </NavLink>
            <a href="#" onClick={e => {
              e.preventDefault()
              setModalRuleStatus(true)
            }} className="menu-link">
              {lng.menu_guide || 'Hướng dẫn'}
            </a>
            <a href="#" className="menu-link" onClick={e => {
              e.preventDefault()
              setModalStatus(true)
            }}>
              {lng.menu_history || 'Lịch sử'}
            </a>
            <a href="#" className="menu-link" onClick={e => {
              e.preventDefault()
              setModalPrizeStatus(true)
            }}>
              {lng.menu_rewards || 'Quà nhận được'}
            </a>
            {userId && (
              <span className="menu-link" onClick={e => {
                e.preventDefault()
              }}>
                <span><strong>Hi {nickname}</strong> | {lng.cumulative_play || 'Số lần chơi'}: {current_hurdle_no} | {lng.free_balance || 'Lượt Free'}: {free_turn_no - free_turn_spent}</span>
              </span>
            )}
            <a href={userId ? '/api/user/logout' : '/api/user/login/'} className="menu-link menu-link--login">
              {!userId ? (
                <span>{lng.login || 'Đăng nhập'}</span>
              ) : (
                <span>{lng.logout || 'Đăng xuất'}</span>
              )}
            </a>
          </div>
        </div>
      </div>
      {modalStatus && (          
        <ModalHistory
          lng={lng}
          userHistory={userHistory}
          isGettingHistory={isGettingHistory}
          getHistory={getHistory}
          modalStatus={modalStatus}
          closeModal={closeModal}
        />
      )}
      {modalPrizeStatus && (          
        <ModalPrize
          lng={lng}
          reward_infos={reward_infos}
          modalStatus={modalPrizeStatus}
          closeModal={closeModalPrize}
        />
      )}
      {modalRuleStatus && (          
        <ModalRule
          lng={lng}
          modalStatus={modalRuleStatus}
          closeModal={closeModalRule}
        />
      )}
    </>
  )
}

export default Header