import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import Scrollbar from 'react-scrollbars-custom'

import config from 'config/common'
import lib from 'lib/commons'
import Spinner from 'components/Spinner'

Modal.setAppElement('#root');

const ModalPrize = ({
  lng,
  reward_infos,
  modalStatus,
  closeModal
}) => {
  
  const [selectRewards, setSelectReward] = useState({})

  const afterOpenModal = () => {
  }

  const selfClose = (event) => {
    event.preventDefault()
    closeModal()
  }

  useEffect(() => {
    const rewards = reward_infos?.filter(i => i.event == 2 || i.event == 3)
    if(rewards?.length) {
      const tmpRewards = {}
      for (let index = 0; index < rewards.length; index++) {
        const item = rewards[index]

        if(!tmpRewards[item?.hurdle]) {
          tmpRewards[item?.hurdle] = [item]
        } else {
          tmpRewards[item?.hurdle] = [
            ...tmpRewards[item?.hurdle],
            item
          ]
        }
      }
      setSelectReward(tmpRewards)
    }
  }, [reward_infos])

  return (
    <Modal
      isOpen={modalStatus}
      onAfterOpen={afterOpenModal}
      onRequestClose={selfClose}
      contentLabel="Example Modal"
      portalClassName="ReactModalPortal"
      overlayClassName=""
      className={`animated fadeInDown faster modal-prize`}
    >
      <h2 className='text-left'>{lng.gift_list}</h2>
      <div className="modal-description text-left">
        <h4>
          <div className="btn btn--transparent">
            {lng.gift_round_1_title}
          </div>
        </h4>
        <div className="row">
          {reward_infos.filter(i => i.event == 1 && i.level == 0).map((item, index) => (
            <div className="col col--prize" key={index}>
              <div className={`cover cover--small${item?.is_completed ? ' cover--special' : ''} has-title`}>
                <span className="cover__img">
                  <img src={item?.item_url?.replace('.png', '_s.png')} alt=""  /> 
                </span>
                <span className="title">{item?.item_name}</span>
              </div>
            </div>
          ))}
        </div>
        <br/>
        <h4>
          <div className="btn btn--transparent">
            {lng.gift_round_2_title}
          </div>
        </h4>
        <div className="row">
          {reward_infos.filter(i => i.event == 1 && i.level == 1).map((item, index) => (
            <div className="col col--prize" key={index}>
              <div className={`cover cover--small${item?.is_completed ? ' cover--special' : ''} has-title`}>
                <span className="cover__img">
                  <img src={item?.item_url?.replace('.png', '_s.png')} alt=""  /> 
                </span>
                <span className="title">{item?.item_name}</span>
              </div>
            </div>
          ))}
        </div>
        <br/>
        <h4>
          <div className="btn btn--transparent">
            {lng.gift_round_3_title}
          </div>
        </h4>
        <div className="row">
          {reward_infos.filter(i => i.event == 1 && i.level == 2).map((item, index) => (
            <div className="col col--prize" key={index}>
              <div className={`cover cover--small${item?.is_completed ? ' cover--special' : ''} has-title`}>
                <span className="cover__img">
                  <img src={item?.item_url?.replace('.png', '_s.png')} alt=""  /> 
                </span>
                <span className="title">{item?.item_name}</span>
              </div>
            </div>
          ))}
        </div>
        <br/>
        <h4>
          <div className="btn btn--transparent">
            {lng.accumulate_gift}
          </div>
        </h4>
        <div className="row">
          {[...Object.values(selectRewards)].sort((a, b) => a[0].hurdle - b[0].hurdle).map((item, index) => (
            <React.Fragment key={index}>
              {item.length == 1 ? (
                <>
                  <div className="col col--prize">
                    <div className={`cover cover--small cover--accumulate has-title`}>
                      <span className="cover__img">
                        <img src={item[0]?.item_url} alt=""  /> 
                      </span>
                      <span className="title">{item[0]?.item_name}</span>
                    </div>
                  </div>
                </>
              ) : (
                <div className={`col col--prize col--prize--select`} key={index}>
                  <div className="cover cover--select text-center">
                    {item.map((itm, idx) => (
                      <React.Fragment key={idx}>
                        <div className="cover__img-select has-title">
                          <img src={itm?.item_url} alt="" />
                          <span className="title">
                            {itm?.item_name}
                          </span>
                        </div>
                        {idx == 0 && (
                          <em>{lng?.or}</em>
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              )}
            </React.Fragment>
          ))}
        </div>

      </div>
      <a onClick={event => selfClose(event)} className="close" data-dismiss="modal" aria-label="Close">
        ×
      </a>
    </Modal>
  )
}
export default ModalPrize