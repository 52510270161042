import request from "lib/request"
import config from "config/app"
import lib from 'lib/commons'
import lang from "lng/index"
const lng = lang[config.lng]

export const CURRENT_USER_REQUEST         = 'CURRENT_USER_REQUEST'
export const CURRENT_USER_REQUEST_ERROR   = 'CURRENT_USER_REQUEST_ERROR'
export const CURRENT_USER_REQUEST_SUCCESS = 'CURRENT_USER_REQUEST_SUCCESS'
export const CURRENT_USER_FETCHING        = 'CURRENT_USER_FETCHING'

export const GET_REWARDS         = 'GET_REWARDS'
export const GET_REWARDS_ERROR   = 'GET_REWARDS_ERROR'
export const GET_REWARDS_SUCCESS = 'GET_REWARDS_SUCCESS'
export const IS_GETTING_REWARDS  = 'IS_GETTING_REWARDS'

export const RECEIVE_MISSION         = 'RECEIVE_MISSION'
export const RECEIVE_MISSION_ERROR   = 'RECEIVE_MISSION_ERROR'
export const RECEIVE_MISSION_SUCCESS = 'RECEIVE_MISSION_SUCCESS'
export const IS_RECEIVING_MISSION    = 'IS_RECEIVING_MISSION'

export const CHECK_MISSION         = 'CHECK_MISSION'
export const CHECK_MISSION_ERROR   = 'CHECK_MISSION_ERROR'
export const CHECK_MISSION_SUCCESS = 'CHECK_MISSION_SUCCESS'
export const IS_CHECKING_MISSION   = 'IS_CHECKING_MISSION'

export const SPIN         = 'SPIN'
export const SPIN_ERROR   = 'SPIN_ERROR'
export const SPIN_SUCCESS = 'SPIN_SUCCESS'
export const IS_SPINNING  = 'IS_SPINNING'

export const SHARE         = 'SHARE'
export const SHARE_ERROR   = 'SHARE_ERROR'
export const SHARE_SUCCESS = 'SHARE_SUCCESS'
export const IS_SHARING    = 'IS_SHARING'

export const GET_HISTORY         = 'GET_HISTORY'
export const GET_HISTORY_ERROR   = 'GET_HISTORY_ERROR'
export const GET_HISTORY_SUCCESS = 'GET_HISTORY_SUCCESS'
export const IS_GETTING_HISTORY  = 'IS_GETTING_HISTORY'

export const CLAIM         = 'CLAIM'
export const CLAIM_ERROR   = 'CLAIM_ERROR'
export const CLAIM_SUCCESS = 'CLAIM_SUCCESS'
export const IS_CLAIMING   = 'IS_CLAIMING'

export const GET_LANG         = 'GET_LANG'
export const GET_LANG_ERROR   = 'GET_LANG_ERROR'
export const GET_LANG_SUCCESS = 'GET_LANG_SUCCESS'
export const IS_GETTING_LANG  = 'IS_GETTING_LANG'

export const REFRESH         = 'REFRESH'
export const REFRESH_ERROR   = 'REFRESH_ERROR'
export const REFRESH_SUCCESS = 'REFRESH_SUCCESS'
export const IS_REFRESHING   = 'IS_REFRESHING'

let tm
let tmResult

export const isCurrentUserFetching = () => {
  return {
    type: CURRENT_USER_FETCHING
  }
}

export const getCurrentUser = () => {
  return (dispatch, getState) => {
    dispatch(isCurrentUserFetching());
    request('api/user/get').then(function(response) {
      if(response.status == 'successful') {
        dispatch(getCurrentUserSuccess(response));
      } else {
        if(response.status == 'no_account') {
          lib.showDownlad()
        }
        dispatch(getCurrentUserError(response))
      }
    })
  }
}

export const getCurrentUserSuccess = (response) => {
  return {
    type: CURRENT_USER_REQUEST_SUCCESS,
    payload: response.payload
  }
}

export const getCurrentUserError = (response) => {
  return {
    type: CURRENT_USER_REQUEST_ERROR,
    payload: response.payload
  }
}

//GET REWARDS
export const isGettingRewards = () => {
  return {
    type: IS_GETTING_REWARDS,
  }
}
export const getRewards = (round_no) => {
  return (dispatch, getState) => {
    dispatch(isGettingRewards());
    request(`api/reward/get_reward_by_round_no${round_no ? `?round_no=${round_no}` : ''}`).then(function(response) {
      if(response.status == 'successful') {
        dispatch(getRewardsSuccess(response))
      } else {
        lib.showError(response.status, getState().currentUser?.lng)
        dispatch(getRewardsError(response))
      }
    })
  }
}
export const getRewardsSuccess = (response) => {
  return {
    type: GET_REWARDS_SUCCESS,
    payload: response.payload
  }
}
export const getRewardsError = (response) => {
  return {
    type: GET_REWARDS_ERROR,
    payload: response.payload
  }
}


//Spin
export const isSpinning = () => {
  return {
    type: IS_SPINNING,
  }
}
export const spin = (play_type, is_free, payment_type, setWinningItem) => {
  return (dispatch, getState) => {
    dispatch(isSpinning());
    request('api/reward/play/', 'POST', {
      body: JSON.stringify({
        play_type: parseInt(play_type),
        is_free: is_free,
        payment_type: payment_type == 'fc' ? 1 : 2,
      })
    }).then(function(response) {
      if(response.status == 'successful') {
        const allItems = getState()?.currentUser?.current_rewards?.filter(i => i?.display_index <= 16 && i?.display_index > 0)
        const remainingItems = []

        for (let index = 0; index < allItems.length; index++) {
          const item = allItems[index]
          if(!item?.claimed) {
            remainingItems.push(item.id)
          }
        }

        const rewardItems = response.payload.reward_infos?.filter(i => !i?.is_special_bonus && !i?.is_completed)

        let winItem = rewardItems?.[rewardItems.length - 1]

        let shuffledArray = lib.shuffleArray([...remainingItems])
        let currentIndex = 0
        let turns = 0
        let maxTurn = remainingItems.length == 1 ? 2 : 4
        
        const latestItems = response.payload.current_rewards //latest slot items status

        tm = setInterval(() => {
          if(currentIndex == shuffledArray.length) {
            currentIndex = 0
          }
          if(turns >= maxTurn && shuffledArray[currentIndex] == winItem.id) {
            clearInterval(tm)
            $(`.cover--id-${winItem.id}`).css('animation', 'won 1.5s')
            setTimeout(() => {
              $(`.cover--id-${winItem.id}`).css('animation', 'none')
            }, 1500)

            let timeOutMessage = 1500
            
            //if got line reward => add effect
            let lineIndex
            
            let ownedSpecialItem = getState()?.currentUser?.current_rewards?.filter(i => i.claimed && i.is_special)?.length
            let newSpecialItem = rewardItems?.filter(i => i.claimed && i.is_special)?.length

            if(ownedSpecialItem + newSpecialItem == 4 && !getState()?.currentUser?.current_rewards?.filter(i => i.claimed && i.is_special_bonus)?.length) {
              timeOutMessage = 2500
              let lineRewards = latestItems.filter(i => i?.is_special).map(item => item.display_index).join('_')
              lineIndex = config.lineIndexMap?.[lineRewards]
              $('.board-spin__pool').addClass('active-line')
            }

            setTimeout(() => {
              lib.showRewards(response.payload.reward_infos, (getState().currentUser?.lng?.title_congratulations || 'Chúc mừng<br>Bạn nhận được'), getState().currentUser?.lng).then(res => {
                $('.board-spin__pool').removeClass('active-line')
                if((response.payload.user_status.round_no == 6 || response.payload.user_status.round_no == 10) && !latestItems.filter(i => i.claimed).length) {
                  let roundNo = response.payload.user_status.round_no == 6 ? 2 : 3
                  lib.showMessage((getState().currentUser?.lng?.round_congratulations || 'Chúc mừng bạn đã mở khóa Vòng [xxx] và nâng cấp quà thành công').replace('[xxx]', roundNo), null, (getState().currentUser?.lng?.notice || 'Thông báo'), (getState().currentUser?.lng?.confirm || 'Xác nhận')).then(res => {
                    dispatch(spinSuccess(response))
                  })
                } else {
                  dispatch(spinSuccess(response))
                }
              })
            }, timeOutMessage)
          }

          setWinningItem(shuffledArray[currentIndex])
          currentIndex++
          turns++
        }, 200)              
      } else {
        lib.showError(response.status, getState().currentUser?.lng)
        dispatch(spinError(response))
      }
    })
  }
}
export const spinSuccess = (response) => {
  return {
    type: SPIN_SUCCESS,
    payload: response.payload
  }
}
export const spinError = (response) => {
  return {
    type: SPIN_ERROR,
    payload: response.payload
  }
}


//Receive mission
export const isReceievingMission = () => {
  return {
    type: IS_RECEIVING_MISSION,
  }
}
export const receiveMission = () => {
  return (dispatch, getState) => {
    dispatch(isReceievingMission());
    request('api/mission/receive/', 'POST', {
      body: JSON.stringify({})
    }).then(function(response) {
      if(response.status == 'successful') {
        lib.showMessage(response.payload?.user_mission_infos?.[0]?.name + ` (${getState().currentUser?.lng?.mission_not_done || 'Chưa hoàn thành'})`, null, (getState().currentUser?.lng?.mission || 'Nhiệm vụ'), (getState().currentUser?.lng?.check_mission || 'Kiểm tra nhiệm vụ')).then(res => {
          dispatch(receiveMissionSuccess(response))
          if(res.value) {
            dispatch(checkMission())
          }
        })
      } else {
        lib.showError(response.status, getState().currentUser?.lng)
        dispatch(receiveMissionError(response))
      }
    })
  }
}
export const receiveMissionSuccess = (response) => {
  return {
    type: RECEIVE_MISSION_SUCCESS,
    payload: response.payload
  }
}
export const receiveMissionError = (response) => {
  return {
    type: RECEIVE_MISSION_ERROR,
    payload: response.payload
  }
}

//Share
export const isSharing = (response) => {
  return {
    type: IS_SHARING,
  }
}
export const share = (shareCustom = null) => {
  return (dispatch, getState) => {
    dispatch(isSharing());
    request('api/mission/update_share_mission', 'POST', {
      body: JSON.stringify({})
    }).then(function(response) {
      if(response.status == 'successful') {
        if(shareCustom) {
          shareCustom()
        } else {          
          lib.showMessage(`<p class="text-center">${getState().currentUser?.lng?.share_success || 'Bạn đã chia sẻ thành công và nhận 1 lượt free'}</p>`, null, (getState().currentUser?.lng?.notice || 'Thông báo'), (getState().currentUser?.lng?.confirm || 'Xác nhận'))
        }
        dispatch(shareSuccess(response))
      } else {
        if(getState()?.currentUser?.user_status?.share_turn_no) {
          lib.showMessage(getState().currentUser?.lng?.share_failed || 'Bạn đã nhận lượt từ lần share trước rồi', null, (getState().currentUser?.lng?.notice || 'Thông báo'), (getState().currentUser?.lng?.confirm || 'Xác nhận'))
        } else {
          lib.showError(response.status, getState().currentUser?.lng)
        }
        dispatch(shareError(response))
      }
    })
  }
}
export const shareSuccess = (response) => {
  return {
    type: SHARE_SUCCESS,
    payload: response.payload
  }
}
export const shareError = (response) => {
  return {
    type: SHARE_ERROR,
    payload: response.payload
  }
}

//Check mission
export const isCheckingMission = (response) => {
  return {
    type: IS_CHECKING_MISSION,
  }
}
export const checkMission = () => {
  return (dispatch, getState) => {
    dispatch(isCheckingMission());
    request('api/mission/update_mission_status/', 'POST', {
      body: JSON.stringify({})
    }).then(function(response) {
      if(response.status == 'successful') {
        let missionText = ''
        let missionStatus = response.payload?.user_mission_infos?.[0]?.status
        if(missionStatus == 2) {
          missionText = getState().currentUser?.lng?.mission_done || 'Đã hoàn thành'
        } else if (missionStatus == 1) {
          missionText = getState().currentUser?.lng?.mission_not_done || 'Chưa hoàn thành'
        }
        lib.showMessage(response.payload?.user_mission_infos?.[0]?.name + ` (${missionText})`, null, (getState().currentUser?.lng?.mission || 'Nhiệm vụ'), (missionStatus == 2 ? (getState().currentUser?.lng?.close || 'Đóng') : (getState().currentUser?.lng?.check_mission || 'Kiểm tra nhiệm vụ'))).then(res => {
          dispatch(checkMissionSuccess(response))
          if(res.value) {
            if(missionStatus == 1) {
              dispatch(checkMission())
            }
          }
        })
      } else {
        lib.showError(response.status, getState().currentUser?.lng)
        dispatch(checkMissionError(response))
      }
    })
  }
}
export const checkMissionSuccess = (response) => {
  return {
    type: CHECK_MISSION_SUCCESS,
    payload: response.payload
  }
}
export const checkMissionError = (response) => {
  return {
    type: CHECK_MISSION_ERROR,
    payload: response
  }
}

//get history
export const isGettingHistory = () => {
  return {
    type: IS_GETTING_HISTORY
  }
}

export const getHistory = () => {
  return (dispatch, getState) => {
    dispatch(isGettingHistory());
    request('api/user/get_reward_infos/').then(function(response) {
      if(response.status == 'successful') {
        dispatch(getHistorySuccess(response))
      } else {
        dispatch(getHistoryError(response))
      }
    })
  }
}

export const getHistorySuccess = (response) => {
  return {
    type: GET_HISTORY_SUCCESS,
    payload: response.payload
  }
}

export const getHistoryError = (response) => {
  return {
    type: GET_HISTORY_ERROR,
    payload: response.payload
  }
}

//Claim
export const isClaiming = (response) => {
  return {
    type: IS_CLAIMING,
  }
}
export const claim = (reward, closeModal = null) => {
  return (dispatch, getState) => {
    dispatch(isClaiming());
    request('api/user/claim_special_exchange/', 'POST', {
      body: JSON.stringify({
        reward_id: parseInt(reward?.id)
      })
    }).then(function(response) {
      if(response.status == 'successful') {
        lib.showSingleReward(reward, null, (getState().currentUser?.lng?.title_congratulations || 'Chúc mừng<br>Bạn nhận được'), (getState().currentUser?.lng?.confirm || 'Xác nhận'))
        dispatch(claimSuccess(response))
        if(closeModal) {
          closeModal()
        }
      } else {
        lib.showError(response.status, getState().currentUser?.lng)
        dispatch(claimError(response))
      }
    })
  }
}
export const claimSuccess = (response) => {
  return {
    type: CLAIM_SUCCESS,
    payload: response.payload
  }
}
export const claimError = (response) => {
  return {
    type: CLAIM_ERROR,
    payload: response.payload
  }
}

//Language
export const isGettingLang = (response) => {
  return {
    type: IS_GETTING_LANG,
  }
}
export const getLang = (code) => {
  return (dispatch, getState) => {
    dispatch(isGettingLang());
    request('api/transify/get?lang=' + code).then(function(response) {
      if(response.status == 'successful') {
        dispatch(getLangSuccess(response))
      } else {
        dispatch(getLangError(response))
      }
    })
  }
}
export const getLangSuccess = (response) => {
  return {
    type: GET_LANG_SUCCESS,
    payload: response.payload
  }
}
export const getLangError = (response) => {
  return {
    type: GET_LANG_ERROR,
    payload: response.payload
  }
}

//Refresh FC
export const isRefreshing = () => {
  return {
    type: IS_REFRESHING,
  }
}
export const refresh = () => {
  return (dispatch, getState) => {
    dispatch(isRefreshing());
    request('api/user/reload_fc', 'POST', {
      body: JSON.stringify({})
    }).then(function(response) {
      if(response.status == 'successful') {
        lib.showMessage((getState().currentUser?.lng?.refresh_success || 'Cập nhật FC, MC thành công'), null, (getState().currentUser?.lng?.notice || 'Thông báo'), null, (getState().currentUser?.lng?.notice || 'Thông báo'), (getState().currentUser?.lng?.confirm || 'Xác nhận')).then(res => {
          dispatch(refreshSuccess(response))
        })
      } else {
        lib.showError(response.status, getState().currentUser?.lng)
        dispatch(refreshError(response))
      }
    })
  }
}
export const refreshSuccess = (response) => {
  return {
    type: REFRESH_SUCCESS,
    payload: response.payload
  }
}
export const refreshError = (response) => {
  return {
    type: REFRESH_ERROR,
    payload: response.payload
  }
}