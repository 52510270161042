export default {
  lng: 'vi', //vi|en
  region: 'VN', //vi|pk
  version: '1',
  domain: 'magicbox.fo4.garena.in.th',
  demo: 0,
  demoMsg: 'Sự kiện sẽ bắt đầu lúc ...',
  baseUrl:  `https://magicbox.fo4.garena.in.th/`,
  shareHashtag: 'FO4',
  shareQuote: 'caption share...',
  lineIndexMap: {
    '1_2_3_4': 4,
    '5_6_7_8': 3,
    '9_10_11_12': 2,
    '13_14_15_16': 1,
    '1_6_11_16': 5,
    '1_5_9_13': 6,
    '2_6_10_14': 7,
    '3_7_11_15': 8,
    '4_8_12_16': 9
  }
}