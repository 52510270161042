import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import Scrollbar from 'react-scrollbars-custom'
import dayjs from 'dayjs'

import config from 'config/common'
import lib from 'lib/commons'
import Spinner from 'components/Spinner'

Modal.setAppElement('#root');

const ModalHistory = ({
  lng,
  userHistory,
  isGettingHistory,
  getHistory,
  modalStatus, closeModal
}) => {

  const [currentTab, setCurrentTab] = useState('gifts')

  useEffect(() => {
    if(!isGettingHistory) {
      getHistory()
    }
  }, [])

  const afterOpenModal = () => {
  }

  const selfClose = (event) => {
    event.preventDefault()
    closeModal()
  }

  const toLevel = (round) => {
    if(round < 6) {
      return `1.${round}`
    }
    if(round > 5 && round < 10) {
      return `2.${round - 5}`
    }
    if(round >= 10) {
      return `3.${round - 9}`
    }
  }

  const giftType = {
    is_special_bonus: lng.history_line_type || 'Đường đặc biệt',
    is_completed: lng.history_complete_type || 'Quà hoàn thành vòng',
    x2: lng.history_x2_type || 'Ô đặc biệt',
  }

  return (
    <Modal
      isOpen={modalStatus}
      onAfterOpen={afterOpenModal}
      onRequestClose={selfClose}
      contentLabel="Example Modal"
      portalClassName="ReactModalPortal"
      overlayClassName=""
      className="animated fadeInDown faster modal-history"
    >
      <div className="modal-description">
        <h2>{lng.history}</h2>
        <>
          <table className="table-history">
            <thead>
              <tr>
                <th width="10%">{lng.field_no}</th>
                <th width="25%">{lng.field_name}</th>
                <th width="35%">{lng.field_source}</th>
                <th width="15%">{lng.field_time}</th>
                <th width="20%">{lng.field_status}</th>
              </tr>
            </thead>
          </table>

          <Scrollbar 
            style={ {height: '20vw'} }
            noScrollX={true}>
            {userHistory && userHistory.length > 0 ? (
              <table className="table-history">
                <tbody>
                  {userHistory.map((his, index) => (
                    <tr key={index}>
                      <td width="10%"><strong>{index + 1}</strong></td>
                      <td width="25%">{his.item_name}</td>
                      <td width="35%">
                        {(his.event == 2 || his.event == 3) ? lng.accumulate_source : (
                          <>
                            {lng.round} {toLevel(his.round_no)} {(his.is_special_bonus || his.is_completed || his.x2) ? '(' : ''}{giftType?.[his.is_special_bonus ? 'is_special_bonus' : (his.is_completed ? 'is_completed' : (his.x2 ? 'x2' : ''))]}{(his.is_special_bonus || his.is_completed || his.x2) ? ')' : ''}
                          </>
                        )}
                      </td>
                      <td width="15%">{dayjs(his.create_time).format('HH:mm DD/MM/YYYY')}</td>
                      <td width="20%">{lng[`status_${his.status}`]}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>{lng.no_history}</p>
            )}
          </Scrollbar>
        </>
      </div>
      <a onClick={event => selfClose(event)} className="close" data-dismiss="modal" aria-label="Close">
        ×
      </a>
    </Modal>
  )
}
export default ModalHistory